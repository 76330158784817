

































import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import MultiSelect from 'vue-multiselect'
import _ from 'lodash'

@Component({
  components: { MultiSelect }
})
export default class SelectField extends AbstractField {
  get trackBy() {
    return this.field.multiple ? 'key' : null;
  }

  get selectedValue() {
    return this.field.multiple
      ? this.field.selectOptions.filter((item: any) => this.value.includes(item.key))
      : _.find(this.field.selectOptions, (item: any) => item.key === this.value);
  }

  set selectedValue(newValue: any) {
    if (!newValue) {
      this.value = null;
      return;
    }
    if (this.field.multiple) {
      this.value = newValue.map((item: any) => item.key);
    } else {
      this.value = newValue.key;
    }
  }
}
